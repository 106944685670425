<template>
  <div v-show="!isLoading">
    <div v-if="!user || !isRegis">
      <MLMenu/>
      <div class="ml-content mt-5 pt-5">
        <div class="ml-goi-cuoc container mb-5">
          <h3 class="text-center">Bạn chưa đăng ký gói cước Tự học trên Ezstudy!</h3>
          <p class="text-center">Vui lòng đăng ký để học nhé!</p>

          <div class="row list-sub justify-content-center">
            <div class="col-lg-3 pb-3" v-for="sub in listSub" :key="sub.id">
              <div class="sub-item">
                <div class="title font-weight-bold text-lg-center">{{ sub.name }} <span class="type d-lg-none">({{ sub.title }})</span></div>
                <div class="price text-lg-center">{{ $helper.formatMoney(sub.price, '.') }}đ <span>/{{ sub.unit }}</span></div>
                <div class="type text-center d-none d-lg-block">{{ sub.title }}</div>
                <div class="other" v-html="sub.description">
                </div>
                <div class="action text-right">
                  <button @click="showMessage(sub)">Đăng ký</button>
                </div>
              </div>
            </div>
          </div>
          <b-modal hide-footer hide-header id="modal-notify-sub" centered v-model="showModal">
            <img src="~/mlearn/icons/modal/close-modal.svg" alt="close" class="close position-absolute pointer" @click="showModal = false"/>
            <div class="title">Thông báo</div>
            <div class="description">{{ message }}</div>
          </b-modal>
        </div>
      </div>
      <MLFooter/>
    </div>
    <div v-else>
      <iframe :src="url"></iframe>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Cookies from 'js-cookie'
import Resource from '~/common/api/resource'
import MLMenu from '~/components/mlearn/layout/menu/menu.vue'
import MLFooter from '~/components/mlearn/layout/footer/footer.vue'
import {getToken} from '~/common/utils/auth'

const ezSubResource = new Resource('price/ezsub')
const ezstudyResource = new Resource('price/subuser/ezstudy')
export default {
  name: 'TuHoc',
  layout: 'empty',
  components: {
    MLMenu,
    MLFooter
  },
  // async asyncData ({
  //   isDev,
  //   route,
  //   store,
  //   env,
  //   params,
  //   req,
  //   res,
  //   redirect,
  //   error
  // }) {
  //   let url = null
  //   let isError = false
  //   let isRegis = false
  //   let message = ''
  //   if (store.state.user) {
  //     const user = store.state.user
  //     let classID = null
  //     if (req && req.headers && req.headers.cookie) {
  //       const requestCookies = cookieParser.parse(req.headers.cookie)
  //       classID = requestCookies.interestedClass || user?.class_id
  //     }
  //
  //     console.log('classID', classID)
  //     const query = {class_id: classID}
  //     const token = store.state.token
  //     try {
  //       const {data} = await ezstudyResource.list(query, token)
  //       url = data
  //       console.log(data)
  //       isRegis = true
  //     } catch (e) {
  //       console.log(e)
  //       if (e.response) {
  //         isError = true
  //         message = e.response.data.message
  //       } else {
  //         isError = true
  //         message = e.message
  //       }
  //     }
  //   }
  //   return {
  //     url,
  //     isError,
  //     message,
  //     isRegis
  //   }
  // },
  data () {
    return {
      isLoading: true,
      showModal: false,
      listSub: [],
      message: '',
      url: '',
      isRegis: false
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },

  mounted () {
    if (!this.user) {
      setTimeout(() => {
        this.$bvModal.show('modal-login')
      }, 100)
    }
  },
  created () {
    this.getLink()
    this.loadSub()
  },
  methods: {
    async getLink () {
      if (this.user.uuid) {
        const classID = Cookies.get('interestedClass') || this.user?.class_id

        const query = {class_id: classID}
        const token = getToken()
        try {
          const {data} = await ezstudyResource.list(query, token)
          this.url = data
          this.isRegis = true
        } catch (e) {
          if (e.response) {
            this.$notify({
              message: e.response.data.message,
              type: 'error'
            })
          } else {
            this.$notify({
              message: e.message,
              type: 'error'
            })
          }
        } finally {
          this.isLoading = false
        }
      }
    },
    async loadSub () {
      const {data} = await ezSubResource.list()
      this.listSub = data
    },
    showMessage (sub) {
      const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
        .includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      if (this.isMobile) {
        if (isIOS) {
          window.open(`sms:${sub.service_number}?&body=${sub.reg_syntax}`, '_blank')
        } else {
          window.open(`sms:${sub.service_number}?body=${sub.reg_syntax}`, '_blank')
        }
        return
      }
      this.showModal = true
      this.message = `Để xác nhận đăng ký ${sub.name}, mời Quý khách soạn tin ${sub.reg_syntax} gửi ${sub.service_number} xác nhận đăng ký DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
    }
  }
}
</script>

<style scoped>
iframe {
  display: block;
  width: 100vw;
  height: 100vh;
  border: none;
  outline: none;
}
</style>
<style lang="scss" scoped>
.ml-goi-cuoc {
  .list-sub {
    .sub-item {
      position: relative;
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding: 24px;
      @media (max-width: 576px) {
        padding: 16px;
      }

      &::after {
        content: '';
        position: absolute;
        background: #4CA3EC;
        box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
        border-radius: 0px 0px 8px 8px;
        height: 8px;
        width: 100%;
        bottom: 0;
        left: 0;
        @media (max-width: 576px) {
          border-radius: 8px 0px 0px 8px;
          height: 100%;
          width: 8px;
          bottom: 0;
          left: 0;
        }
      }

      .title {
        font-size: 30px;

        .type {
          font-size: 12px;
          color: #65676B;
        }

        @media (max-width: 576px) {
          font-size: 16px;
        }
      }

      .price {
        font-weight: 600;
        color: var(--primary);
        font-size: 18px;
        @media (max-width: 576px) {
          padding-top: 16px;
        }

        span {
          font-size: 18px;
          @media (max-width: 576px) {
          }
        }
      }

      .type {
        font-size: 15px;
        color: #65676B;
        font-style: italic;
      }

      .other {
        border-top: 1px solid #D4D4D4;
        color: #65676B;
        white-space: break-spaces;
        line-height: 200%;
        font-size: 16px;
        margin-top: 20px;
        padding-top: 20px;

        @media (max-width: 576px) {
          font-size: 13px;
          margin-top: 8px;
        }

        ul {
          padding-left: 20px;

          li {
            list-style: none
          }
        }
      }

      .action {
        margin-top: 40px;
        @media (max-width: 576px) {
          margin-top: 16px;
        }

        button {
          background: var(--primary);
          box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          color: var(--white);
          width: 100%;
          padding: 14px 0;
          @media (max-width: 1366px) {
            padding: 14px 0;
          }
          @media (max-width: 576px) {
            padding: 10px 30px;
            width: auto;
          }

          &:hover {
            box-shadow: 0px 3px 6px rgba(202, 202, 202, 0.34);
          }
        }
      }
    }
  }
}
</style>
